var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.data},scopedSlots:_vm._u([{key:"songInfo",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"util-button-2 fw-bold",attrs:{"to":("/intellectualproperty/registersongs/" + (row.requestId))}},[_vm._v(" "+_vm._s(row.publicNote || _vm.$t('R.LIT_RecentSongUntitled'))+" ")])]}},{key:"lastModified",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.rowModifiedBy)+" - "+_vm._s(_vm._f("formatDate")(row.rowModifiedDate))+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getStatus(row))+" ")])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('FButton',{attrs:{"utility":"2","before-icon":"fa-light fa-trash-alt"},on:{"click":function($event){return _vm.removeSong(row)}}},[_vm._v(" "+_vm._s(_vm.$t('R.LIT_Remove'))+" ")])]}},{key:"mobile",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('router-link',{staticClass:"fw-bold",attrs:{"to":("/intellectualproperty/registersongs/" + (row.requestId))}},[_vm._v(" "+_vm._s(row.publicNote || _vm.$t('R.LIT_RecentSongUntitled'))+" ")])],1),_c('div',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$t('R.LIT_RecentLastModified'))+": "+_vm._s(row.rowModifiedBy)+" - "+_vm._s(_vm._f("formatDate")(row.rowModifiedDate))+" ")]),_c('div',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$t('R.LIT_RecentStatus'))+": "+_vm._s(_vm.getStatus(row))+" ")]),_c('FButton',{attrs:{"utility":"2","before-icon":"fa-light fa-trash-alt"},on:{"click":function($event){return _vm.removeSong(row)}}},[_vm._v(" "+_vm._s(_vm.$t('R.LIT_Remove'))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }